//      

                                     
                                                
                                                     
                                                            

class Var                       {
                
               
                 

    constructor(key        , name        , type      ) {
        this.key = key;
        this.type = type;
        this.name = name;
    }

    static parse(args              , context                ) {
        if (args.length !== 2 || typeof args[1] !== 'string')
            return context.error(`'var' expression requires exactly one string literal argument.`);

        const name = args[1];
        if (!context.scope.has(name)) {
            return context.error(`Unknown variable "${name}". Make sure "${name}" has been bound in an enclosing "let" expression before using it.`, 1);
        }

        return new Var(context.key, name, context.scope.get(name).type);
    }

    evaluate(ctx                   ) {
        return ctx.scope.get(this.name).evaluate(ctx);
    }

    eachChild() {}
}

module.exports = Var;
