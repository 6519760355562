//      

const {
    array,
    ValueType,
    NumberType
} = require('../types');

const RuntimeError = require('../runtime_error');

                                                
                                                     
                                                           
                                                
                                       

class At                       {
                
               
                      
                      

    constructor(key        , type      , index            , input            ) {
        this.key = key;
        this.type = type;
        this.index = index;
        this.input = input;
    }

    static parse(args              , context                ) {
        if (args.length !== 3)
            return context.error(`Expected 2 arguments, but found ${args.length - 1} instead.`);

        const index = context.parse(args[1], 1, NumberType);
        const input = context.parse(args[2], 2, array(context.expectedType || ValueType));

        if (!index || !input) return null;

        const t            = (input.type     );
        return new At(context.key, t.itemType, index, input);
    }

    evaluate(ctx                   ) {
        const index = ((this.index.evaluate(ctx)     )        );
        const array = ((this.input.evaluate(ctx)     )              );

        if (index < 0 || index >= array.length) {
            throw new RuntimeError(`Array index out of bounds: ${index} > ${array.length}.`);
        }

        if (index !== Math.floor(index)) {
            throw new RuntimeError(`Array index must be an integer, but found ${index} instead.`);
        }

        return array[index];
    }

    eachChild(fn                      ) {
        fn(this.index);
        fn(this.input);
    }
}

module.exports = At;
